import React from 'react'

import { Route } from 'react-router-dom'
import { AnimatedSwitch } from 'react-router-transition'

import CVPage from './pages/CVPage.js'
import ProjectsPage from './pages/ProjectsPage.js'

import VRGlove from './components/Projects/VRGlove.js'

const Router = () => {
  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      className="switchWrapper"
    >
      <Route exact path="/projects" component={ProjectsPage} />
      <Route exact path="/vr-glove" component={VRGlove} />
      <Route path="/" component={CVPage} />
    </AnimatedSwitch>
  )
}

export default Router

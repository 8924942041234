import React from 'react'

const name = 'Peter Slavov'
const abstract =
  'Interested in all aspects of science and technology. Software & web developer, hardware enthusiast.'

class Abstract extends React.Component {
  render() {
    return (
      <div>
        <div class="ps-color ps-title">{name}</div>
        <p>{abstract}</p>
      </div>
    )
  }
}
export default Abstract

import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'

import Projects from '../components/Projects/Projects.js'

class ProjectsPage extends React.Component {
  render() {
    return (
      <div className="transition-item detail-page">
        <Grid>
          <Row>
            <Col className="ps-padding-sides">
              <Projects />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}
export default ProjectsPage

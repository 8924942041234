import React from 'react'
import { Link } from 'react-router-dom'

import SpaceStartup from './SpaceStartup.js'
import Highlights from './Highlights.js'
import VRGlove from './VRGlove.js'

const Projects = () => {
  return (
    <div>
      <Link to={'/projects/'} style={{ textDecoration: 'none' }}>
        <h3 className="ps-color">Projects</h3>
      </Link>
      <SpaceStartup type="fragment" />
      <Highlights type="fragment" />
      <VRGlove type="fragment" />
    </div>
  )
}
export default Projects

import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'

import { Link } from 'react-router-dom'

class VRGlove extends React.Component {
  render() {
    const { type } = this.props

    switch (type) {
      default:
      case 'fragment':
        return (
          <div className="project-section">
            <Grid>
              <Row>
                <Link className="ps-color" to={'vr-glove'}>
                  VR Glove
                </Link>
                <p>
                  A glove that utilises several IMUs and flex sensors to pass a
                  quaternion representation of the user’s hand orientation to a
                  computer. A proof-of-concept video{' '}
                  <a
                    className="ps-link-color"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://youtu.be/fkMuwC7-AlU"
                  >
                    can be seen here
                  </a>
                  . This was built using an Arduino (C, C#) and electronics.
                </p>
              </Row>
            </Grid>
          </div>
        )
      case 'page':
        return (
          <div className="transition-item detail-page">
            <Grid>
              <Row>
                <Col xs={12} className="ps-padding-sides">
                  <p>
                    A glove that utilises several IMUs and flex sensors to pass
                    a quaternion representation of the user’s hand orientation
                    to a computer. A proof-of-concept video can be seen below.
                    This was built using an Arduino (C, C#) and electronics.
                  </p>
                  <Row center="xs">
                    <iframe
                      title="Glove project video"
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/fkMuwC7-AlU"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </Row>
                </Col>
              </Row>
            </Grid>
          </div>
        )
    }
  }
}
export default VRGlove

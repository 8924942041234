import React from 'react'
import { Link } from 'react-router-dom'

class TopLink extends React.Component {
  render() {
    const { link, name } = { ...this.props }
    return (
      <div className="top-link">
        <Link to={link}>{name}</Link>
      </div>
    )
  }
}

export default TopLink

import React from 'react'

class WorkSection extends React.Component {
  render() {
    const { period, position } = { ...this.props }
    const paragraphs = this.props.paragraphs.map((paragraph, i) => (
      <p key={i}>{paragraph}</p>
    ))
    return (
      <div className="work-section">
        <p className="ps-color">{position}</p>
        <p className="ps-color">{period}</p>
        {paragraphs}
      </div>
    )
  }
}
export default WorkSection

import React from 'react'

import EducationSection from './EducationSection.js'

const sections = [
  {
    period: '2010 – 2013',
    establishment: 'Durham University | Durham',
    award: 'Bachelor of Science in Physics and Computer Science',
  },
]
const sectionComponents = sections.map((section, i) => (
  <EducationSection {...section} key={i} />
))

class Education extends React.Component {
  render() {
    return (
      <div>
        <h3 className="ps-color">Education</h3>
        {sectionComponents}
      </div>
    )
  }
}
export default Education

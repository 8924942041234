import React from 'react'

class EducationSection extends React.Component {
  render() {
    const { period, establishment, award } = { ...this.props }
    return (
      <div className="education-section">
        <p class="ps-color">{establishment}</p>
        <p class="ps-color">{period}</p>
        <p>{award}</p>
      </div>
    )
  }
}
export default EducationSection

import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'

import Abstract from '../components/Abstract/Abstract.js'
import Contact from '../components/Contact/Contact.js'

import Work from '../components/Work/Work.js'
import Skills from '../components/Skills/Skills.js'

import Education from '../components/Education/Education.js'
import Projects from '../components/Projects/Projects.js'

import Interests from '../components/Interests/Interests.js'
import Languages from '../components/Languages/Languages.js'

class CVPage extends React.Component {
  render() {
    return (
      <div className="transition-item detail-page">
        <Grid>
          <Row>
            <Col xs={12} sm={6}>
              <Abstract />
            </Col>
            <Col xs={12} sm={6}>
              <Contact />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <Work />
            </Col>
            <Col xs={12} sm={6}>
              <Education />
              <Skills />
              <Projects />
              <Languages />
              <Interests />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}
export default CVPage

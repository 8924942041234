import React from 'react'

import WorkSection from './WorkSection.js'

const sections = [
  {
    period: 'January 2021 – Present',
    position: 'Lead UI Developer | LOQBOX',
    paragraphs: [],
  },
  {
    period: 'January 2019 – January 2021',
    position: 'Front-End Developer | LOQBOX',
    paragraphs: [
      'At LOQBOX I am solely responsible for the (technical) design and architecture of a modern front-end web-app using a React ecosystem.',
      'With a scalable and low-maintenance infrastructure as my top priority during the initial build, my focus recently has been on developing extra features and functionality, as well as managing, upskilling and mentoring the rest of the front-end team (4-6 people).',
      'I’m also leading the design and architecture of a new React Native mobile app, making decisions about the tech stack and writing up the business proposal for it.',
      'In my time with LOQBOX, I have been involved in all aspects of the software lifecycle - from code quality maintenance and peer reviews, through integrating testing frameworks and CI/CD pipelines, all the way to deploying code to live cloud environments.',
    ],
  },
  {
    period: 'January 2016 – December 2018',
    position: 'Lead Software and System Engineer | FlexiDB',
    paragraphs: [
      'At FlexiDB I worked on several React/Redux based apps, including a social media analytics app, productivity mind-mapping software, real-time sensor-driven control system and a brewery notification system. All of those were powered by an in-house database framework, which I also helped develop.',
      'As a system engineer, I created servers and sites on various cloud platforms, set up scalable containerised environments for the apps to run in, prepared CI/CD pipelines and maintained DNS records and Git repositories.',
      'I was also responsible for mentoring a junior developer and helped manage the team’s workload in order to meet deadlines and hit targets.',
    ],
  },
  {
    period: 'February 2014 – December 2015',
    position: 'Software and System Developer | Simple Answers',
    paragraphs: [
      'Worked on a 3D oil and gas visualisation software built with .NET and C#. I also helped manage a team of 3D modellers whose work had to be programmatically modified before being used in the application.',
    ],
  },
]
const sectionComponents = sections.map((section, i) => (
  <WorkSection {...section} key={i} />
))

class WorkExperience extends React.Component {
  render() {
    return (
      <div>
        <h3 className="ps-color">Work Experience</h3>
        {sectionComponents}
      </div>
    )
  }
}
export default WorkExperience

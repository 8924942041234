import React from 'react'

import SkillsSection from './SkillsSection.js'

const sections = [
  {
    title: 'Languages',
    skills: [
      'JavaScript',
      'HTML',
      'CSS',
      'PHP',
      'C#',
      'Java',
      'Python',
      'SQL',
      'C',
      'C++',
    ],
  },
  {
    title: 'Tools',
    skills: [
      'NodeJS',
      'Apache/NGINX',
      'Git',
      'Docker',
      'Kubernetes',
      'AWS',
      'GCP',
      'VMware',
      'Jira',
      'Arduino',
      'Unity',
      '.NET',
      'Blender',
      'MATLAB',
      'OpenGL',
    ],
  },
  {
    title: 'Frameworks',
    skills: [
      'React',
      'Redux',
      'Webpack',
      'Lodash',
      'jQuery',
      'Angular',
      'Jest',
      'Cypress',
      'Laravel',
      'WordPress',
      'Craft',
    ],
  },
]
const sectionComponents = sections.map((section, i) => (
  <SkillsSection {...section} key={i} />
))

class Skills extends React.Component {
  render() {
    return (
      <div>
        <h3 className="ps-color">Technical Skills</h3>
        {sectionComponents}
      </div>
    )
  }
}
export default Skills

import React from 'react'

class Highlights extends React.Component {
  render() {
    const { type } = this.props

    switch (type) {
      default:
      case 'fragment':
        return (
          <div className="project-section">
            <a className="ps-color" rel="noopener noreferrer" href="/">
              Highlights
            </a>
            <p>
              A puzzle game that requires players to guess the title of a film
              or video game based on watching a fragment of a trailer or using
              hints.
              {/* Can be{' '}
              <a
                className="ps-link-color"
                target="_blank"
                rel="noopener noreferrer"
                href="/"
              >
                seen in action here
              </a>. */}
              Built using React, Redux, and Firebase.
            </p>
          </div>
        )
    }
  }
}
export default Highlights

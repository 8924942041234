import React from 'react'

class Interests extends React.Component {
  render() {
    return (
      <div>
        <h3 className="ps-color">Interests</h3>
        <p>
          Science and technology, Hardware and electronics, Space, Video games,
          Snowboarding, Thaiboxing
        </p>
      </div>
    )
  }
}
export default Interests

import React from 'react'

const email = 'peter5slavov@gmail.com'
const phone = '+44 (0) 7725 345 763'
const location = 'Bristol, UK'
const website = 'peter-slavov.com'

class Abstract extends React.Component {
  render() {
    return (
      <div class="ps-float-right ps-contact">
        <p>
          <a href={`mailto:${email}`}>{email}</a>
        </p>
        <p>{phone}</p>
        <p>{location}</p>
        <p>
          <a href={`http://${website}/`}>{website}</a>
        </p>
      </div>
    )
  }
}
export default Abstract

import React from 'react'

class SkillsSection extends React.Component {
  render() {
    const { title } = this.props
    let skills = ''
    for (var i = 0; i < this.props.skills.length; i++) {
      const comma = i === this.props.skills.length - 1 ? '' : ', '
      skills += this.props.skills[i] + comma
    }
    return (
      <p>
        <span className="ps-color skill-block">{title}:</span>
        <span>{skills}</span>
      </p>
    )
  }
}
export default SkillsSection

import React from 'react'

class Language extends React.Component {
  render() {
    return (
      <div>
        <h3 className="ps-color">Languages</h3>
        <p>
          English (fluent), Bulgarian (native), Russian (basic), German (basic)
        </p>
      </div>
    )
  }
}
export default Language

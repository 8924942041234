import React from 'react'

import { BrowserRouter } from 'react-router-dom'

import { Grid, Row, Col } from 'react-flexbox-grid'

import TopLink from './components/TopLink/TopLink'

import Router from './Router'

import './App.scss'

const App = () => {
  return (
    <BrowserRouter>
      <Grid>
        <Row>
          <Col xs={12} smOffset={4} sm={8} lgOffset={6} lg={6}>
            <div className="top-navigation">
              <TopLink link="/" name="CV" />
              <TopLink link="/projects" name="Projects" />
              <div className="top-link">
                <a
                  href="https://d1485xs67oshpw.cloudfront.net/cv/Peter-Slavov-CV-2021.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  Get PDF
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>

      <Router />
    </BrowserRouter>
  )
}

export default App

import React from 'react'

class SpaceStartup extends React.Component {
  render() {
    const { type } = this.props

    switch (type) {
      default:
      case 'fragment':
        return (
          <div className="project-section">
            <a
              className="ps-color"
              target="_blank"
              rel="noopener noreferrer"
              href="https://spacestartup3d.com/"
            >
              Space Startup
            </a>
            <p>
              Based on the original{' '}
              <a
                className="ps-link-color"
                target="_blank"
                rel="noopener noreferrer"
                href="http://space.monopoly.iwarp.com/"
              >
                Space Monopoly
              </a>{' '}
              game, this is a Unity remake with added online gameplay. Still
              under development, but a dev version can be{' '}
              <a
                className="ps-link-color"
                target="_blank"
                rel="noopener noreferrer"
                href="https://spacestartup3d.com/"
              >
                played here
              </a>
              . Written in C#.
            </p>
          </div>
        )
    }
  }
}
export default SpaceStartup
